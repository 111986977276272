import "bootstrap/dist/css/bootstrap.css";
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import news from '../images/news.jpg';
import press from '../images/press.jpg';
import deliver from '../images/Claim_Red.png'
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';


 function NewsRoom() {

  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name"> News Room
														</h1>
														<div className="page-title__content--desc"> 
                                                        Latest news plus more from Prudential Zenith Life!
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={news} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>






<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Know </strong> <span className="lightFont"> What you're looking for ? </span> </h1>
<p> Find your topic based on these categories </p>
</div>

<div className="col-12 inlineDisplay"> 
<div className="card card-static inlineMargin paddingOff" style={{width: "25rem"}}>
<Link to="/news/press-release" className="link">
  <img src={press} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Press releases </h2>
    <p className="card-text"> Find official news from PZL </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
  
    </div>
    </Link>

      </div>


</div>
</div>
</div>
</section>
<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default NewsRoom;