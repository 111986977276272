import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import mission from '../images/mission.jpg';
import purpose from '../images/purpose.jpg';
import civic from '../images/civic.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import boss from '../images/pru.jpg';
import Navigation from './nav';
import Footer from './footer.js';

import {
  InMemoryCache,
  ApolloClient,
  gql,
} from '@apollo/client';

const client = new ApolloClient({
  cache:  new InMemoryCache(),
  uri: "https://pzl-cms.inspireleadership.biz/graphql"
});



 function About() {
  window.scrollTo(0, 0)

  const[hero, setHero] = useState('')
  const[shortDescription, setShortDescription] = useState('')
  const[longDescription, setLongDescription] = useState("")
  const[longDescriptionb, setLongDescriptionb] = useState("")
  const[longDescriptionc, setLongDescriptionc] = useState("")
  const[ceo, setCeo] = useState('')
  const[investorPhoto, setInvestorPhoto]  = useState('')
  const[missionPhoto, setMissionPhoto] = useState('')
  const[purposePhoto, setPurposePhoto] = useState('')


let ourStory = ""
let ourStoryb = ""
let ourStoryc = ""
useEffect( () => {
  client
.query({
  query: gql`
   query{
    pages {
      edges {
        node {
          about {
            aboutdescription
            ceophoto {
              mediaItemUrl
            }
            herophoto {
              mediaItemUrl
            }
            investorphoto {
              mediaItemUrl
            }
            missionphoto {
              mediaItemUrl
            }
            ourstory {
              textarea1
              textarea2
              textarea3
            }
            purposephoto {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
  `,
})
.then(async result => {
  
   console.log(result)
  //alert(result.data.vehicles.edges.length)

 
       
  setHero(result.data.pages.edges[7].node.about.herophoto.mediaItemUrl)
  setCeo(result.data.pages.edges[7].node.about.ceophoto.mediaItemUrl)
  setInvestorPhoto(result.data.pages.edges[7].node.about.investorphoto.mediaItemUrl)
  setShortDescription(result.data.pages.edges[7].node.about.aboutdescription)

  setMissionPhoto(result.data.pages.edges[7].node.about.missionphoto.mediaItemUrl)
  setPurposePhoto(result.data.pages.edges[7].node.about.purposephoto.mediaItemUrl)

 
  ourStory =result.data.pages.edges[7].node.about.ourstory[0].textarea1
  ourStoryb = result.data.pages.edges[7].node.about.ourstory[0].textarea2
  ourStoryc = result.data.pages.edges[7].node.about.ourstory[0].textarea3

  setLongDescription(ourStory)
  setLongDescriptionb(ourStoryb)
  setLongDescriptionc(ourStoryc)
  



});

}, [])   







  return(
       
<div className="a">

<header> 
   <Navigation />
</header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name">About Us: <span className="color-red"> Prudential Life Insurance </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                       {shortDescription}
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer1">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
          
          
  <div className="container">

  <div className="col-12 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Our </strong> <span className="lightFont"> Story </span> </h1>

</div>
     <div className="component-rich-text__wrapper">
<div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">


<p className="editorial-body-copy"> {longDescription}</p>
<p className="editorial-body-copy"> {longDescriptionb}</p>
<p className="editorial-body-copy"> {longDescriptionc}</p>
  </div>
</div>
</div>
</div>


            </div>
        </section>



        <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 

<div className="component-rich-text__wrapper">
    
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h2 className="basic-panel__content--title"> <strong> Our </strong> <span className="lightFont"> Purpose & Mission </span> </h2>

</div>

<div className="row inlineDisplay"> 
<div className="card card-static inlineMargin carouselCard paddingOff">
  <img src={purposePhoto} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Purpose </h2>
    <p className="card-text"> For every life, For every future. </p>
       </div>

      </div>

<div className="card card-static inlineMargin carouselCard paddingOff">
  <img src={missionPhoto} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Mission </h2>
    <p className="card-text"> To be the most trusted partner and protector for this generation and generations to come </p>
    </div>

      </div>


</div>
</div>
</div>
</section>



<section data-pru-link="" class="section pd-100-60 basic-panel info ">
					        <div class="basic-panel__wrapper">
					          <div class="container">
					            <div class="row">
					              <div class="basic-panel__outer col-sm-12">
					                <div class="basic-panel__outer--img">
					                  <picture>
						                <source media="(max-width: 992px)" data-srcset="/export/sites/prudential-pamb/en/.galleries/images/lim-eng-seong-pamb-ceo-360x480.jpg" srcset="/export/sites/prudential-pamb/en/.galleries/images/lim-eng-seong-pamb-ceo-360x480.jpg"/>
						                <img class=" lazyloaded" src={ceo} data-src="/export/sites/prudential-pamb/en/.galleries/images/lim-eng-seong-pamb-ceo-1260x600.jpg" alt="Prudential CEO "/>
						              </picture>
					                </div>
					              </div>
					              <div class="basic-panel__content col-sm-12 col-md-5">
					                <div class="basic-panel__content--wrapper">
									<h1 class="basic-panel__content--title">
						              	Leadership <br/> <span class="lightFont redText"> About the team at the helm of our company</span>
						                </h1>
									 <Link class="heavyFont link" to="/about/leadership" title="Learn more">Learn more  <img src={arrow} /> </Link>
							              	</div>
					              </div>
					            </div>
					          </div>
					        </div>
					      </section>


<div className="container widthOverride"> 

<section className="section pd-100-60 basic-panel promotion" data-pru-link="">
<div className="row clearSpaceBottom1">

<div className="col-12 col-md-5 paddingOff d-none d-md-flex"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                        <div className="basic-panel__content">
                                            <div className="basic-panel__content--wrapper">
                                                <h2 className="basic-panel__content--title">
                                                    <a name="easyswitch" id="easyswitch"></a>
                                                    <h1 className="bannerText basic-panel__content--title"> Investor relations <br/> <span className="lightFont"> Fund report and financial statements </span> </h1>
                                                    
                                                </h2>


                                         

                                                        <Link className="btn-link gtm_PromotionBanner link" to="/about/statements" title="Learn more">Learn More <img src={arrow} />  </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

<div className="col-12 col-md-7"> 
                <div className="basic-panel__wrapper">
                    <div className="container">
                            <div className="basic-panel__outer1">
                                <div className="basic-panel__outer--img">
                                    <picture>
                                        <source media="(max-width: 992px)" data-srcset=""/>
                                            <img className=" ls-is-cached lazyloaded" src={investorPhoto} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                    </picture>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>


                            <div className="col-12 col-md-5 paddingOff d-block d-md-none"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                        <div className="basic-panel__content">
                                            <div className="basic-panel__content--wrapper">
                                                <h2 className="basic-panel__content--title">
                                                    <a name="easyswitch" id="easyswitch"></a>
                                                    <h1 className="bannerText basic-panel__content--title"> Investor relations <br/> <span className="lightFont"> Fund report and financial statements </span> </h1>
                                                    
                                                </h2>


                                         

                                                        <Link className="btn-link gtm_PromotionBanner link" to="/about/statements" title="Learn more">Learn More <img src={arrow} />  </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                 
                
           
                </div>
            </section>

</div>


<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default About;