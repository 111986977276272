import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import test from '../images/cover.jpg';
import Lana from '../images/lana.jpg';
import hospital from '../images/hospital.jpg';
import simulate from '../images/simulate.png';
import protection from '../images/protection.jpg';
import life from '../images/life.jpg';
import advisor from '../images/advisor.jpg';
import renew from '../images/female.jpg';

import OwlCarousel from 'react-owl-carousel';
import OwlCarousel1 from 'react-owl-carousel';
import OwlCarousel2 from 'react-owl-carousel';
import OwlCarousel3 from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg'
import Footer from './footer.js';
import Navigation from './nav';

import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
    


 function Individual() {

  window.scrollTo(0, 0)


let productsArray = []
let lifeblogArray = []
let healthblogArray = []
let marketsblogArray = []
let marketBlogDetails

 const [hero, setHero] = useState([]) 
 const [intro, setIntro] = useState([])
 const [productSpotlight, setProductSpotlight] = useState([])
 const [renewPlan, setRenewPlan] = useState("")
 const [makeClaims, setMakeClaims] = useState("")
 const [advisorContact, setAdvisorContact] = useState("")
 const [sections, setSections] = useState([])
 const [products, setProducts] = useState([])
 const [lifeBlogs, setLifeBlogs ] = useState([])
 const [healthBlogs, setHealthBlogs] = useState([])
 const [marketBlogs, setMarketBlogs] = useState([])
 const  [healthTest, setHealthTest] = useState("")
 




  useEffect( () => {
    client
    .query({
    query: gql`
     query{
      pages {
        edges {
          node {
            home {
              landinghero {
                submessage
                message
                image {
                  mediaItemUrl
                }
                mobileimage{
                  mediaItemUrl
                }
              }
              companyintro {
                boldtextheading
                lighttextheading
                descriptiona
                descriptionb
              }
              productspotlight {
                image {
                  mediaItemUrl
                }
                boldtext
                lighttext
                subheading
                linkname
                link
              }
              renewplan {
                mediaItemUrl
              }
              makeclaim {
                mediaItemUrl
              }
              advisorcontact {
                mediaItemUrl
              }
              othersections {
                image {
                  mediaItemUrl
                }
                boldtext
                subheading
                linkname
                link
              }
            }
          }
        }
      }
    }
    `,
    })
    .then(async result => {
    let heroHolder=[]
    let spotlightHolder=[]
    let introHolder = []
    let sectionsHolder = []


     console.log(result)
    //alert(result.data.vehicles.edges.length)  

     setRenewPlan(result.data.pages.edges[9].node.home.renewplan.mediaItemUrl)
     setMakeClaims(result.data.pages.edges[9].node.home.makeclaim.mediaItemUrl)
     setAdvisorContact(result.data.pages.edges[9].node.home.advisorcontact.mediaItemUrl)
     

     for(let x=0; x < result.data.pages.edges[9].node.home.landinghero.length; x++ ){
          
      let heroDetails = [{mobileimage:result.data.pages.edges[9].node.home.landinghero[x].mobileimage.mediaItemUrl, image:result.data.pages.edges[9].node.home.landinghero[x].image.mediaItemUrl, message:result.data.pages.edges[9].node.home.landinghero[x].message, submessage:result.data.pages.edges[9].node.home.landinghero[x].submessage}]

      heroHolder.push(heroDetails)
     }

     for(let x=0; x < result.data.pages.edges[9].node.home.companyintro.length; x++ ){
          
      let introDetails = [{boldText:result.data.pages.edges[9].node.home.companyintro[x].boldtextheading, lightText:result.data.pages.edges[9].node.home.companyintro[x].lighttextheading, descriptiona:result.data.pages.edges[9].node.home.companyintro[x].descriptiona, descriptionb:result.data.pages.edges[9].node.home.companyintro[x].descriptionb}]

      introHolder.push(introDetails)
     }

     for(let x=0; x < result.data.pages.edges[9].node.home.productspotlight.length; x++ ){
          
      let spotlightDetails = [{image:result.data.pages.edges[9].node.home.productspotlight[x].image.mediaItemUrl, lightText:result.data.pages.edges[9].node.home.productspotlight[x].lighttext, boldText:result.data.pages.edges[9].node.home.productspotlight[x].boldtext, subheading:result.data.pages.edges[9].node.home.productspotlight[x].subheading, linkname:result.data.pages.edges[9].node.home.productspotlight[x].linkname, link:result.data.pages.edges[9].node.home.productspotlight[x].link}]

      spotlightHolder.push(spotlightDetails)
     }

     for(let x=0; x < result.data.pages.edges[9].node.home.othersections.length; x++ ){
          
      let sectionsDetails = [{image:result.data.pages.edges[9].node.home.othersections[x].image.mediaItemUrl, boldText:result.data.pages.edges[9].node.home.othersections[x].boldtext, subheading:result.data.pages.edges[9].node.home.othersections[x].subheading, linkname:result.data.pages.edges[9].node.home.othersections[x].linkname, link:result.data.pages.edges[9].node.home.othersections[x].link}]

      sectionsHolder.push(sectionsDetails)
     }
     //console.log(leadersHolder)
     setHero(heroHolder)
     setProductSpotlight(spotlightHolder)
     setSections(sectionsHolder)
     setIntro(introHolder)

    // console.log(leaders)
    
    });




    client
    .query({
    query: gql`
     query{
      allPolicyPages(first:100) {
        edges {
          node {
            policy {
              blackheadingtext
              productdescription
              productimage {
                mediaItemUrl
              }
            }
            id
            categories {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    }
    `,
    })
    .then(async result => {

       console.log(result)


       let productDetailPack 
    
       

       let x 

      // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

      if (productsArray.length != result.data.allPolicyPages.edges.length){
        for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
           //console.log(x)
    //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
      
              
           productDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext, description:result.data.allPolicyPages.edges[x].node.policy.productdescription, image:result.data.allPolicyPages.edges[x].node.policy.productimage.mediaItemUrl }]
           productsArray.push(productDetailPack)
            
    }

  }
    setProducts(productsArray)

    })



   

     

   
      client
      .query({
      query: gql`
      query{
        allBlogPages {
          edges {
            node {
              id
              categories {
                edges {
                  node {
                    name
                  }
                }
              }
              blogSingle {
                blogheading
                coverimage {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
     `,
  
      })
      .then(async result => {
        console.log(result)
        
        
        let lifeblogDetailPack = []
        let healthblogDetailPack = []
        let marketsDetailPack = []
        lifeblogArray = []
        healthblogArray = []
        marketsblogArray = []
        let x = 0
        
         // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){
    
         if ( x < result.data.allBlogPages.edges.length - 1){
      
           for(x ; x < result.data.allBlogPages.edges.length; x++){ 
              //console.log(x)
       //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
             
            if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'lifeblog'){
              lifeblogDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading, image:result.data.allBlogPages.edges[x].node.blogSingle.coverimage.mediaItemUrl }]
              lifeblogArray.push(lifeblogDetailPack)
            }

            if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'healthblog'){
              healthblogDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading, image:result.data.allBlogPages.edges[x].node.blogSingle.coverimage.mediaItemUrl }]
              healthblogArray.push(healthblogDetailPack)
            }

            if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'Markets'){
              marketsDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading, image:result.data.allBlogPages.edges[x].node.blogSingle.coverimage.mediaItemUrl }]
              marketsblogArray.push(marketsDetailPack)
            }
                 
             
               
       }
   
 
   
       setLifeBlogs(lifeblogArray)
       setHealthBlogs(healthblogArray)
       setMarketBlogs(marketsblogArray)
        console.log(lifeBlogs)
        console.log(healthBlogs)
        console.log(marketBlogs)

        }

          
      })
  





    


    
    }, [])   





    let heroDetailsPack
    
   function heroCall(){ 

   if(hero.length > 0){ 
  
    heroDetailsPack = hero.map((hero, i) => 
      
      <Carousel.Item className="landingCarousel" >
        <span className="elementor-background-overlay"></span>
        <img src={hero[0].image} width="100%" className="landingCarousel zoom" />
        <Carousel.Caption className="landingCarouselCaption">
          <h1 className="bannerText2"> {hero[0].message} </h1>
          <p> {hero[0].submessage} </p>
          <button className="cta-primary hero-banner__content--btn buy" >
            
             <span>Buy A Policy</span>
		
        </button>

        </Carousel.Caption>
      </Carousel.Item>

      
    
    )
    

     }

   }

   $.when(heroCall()).then(function(){ 
    $(".buy").click(function() {
  
      $('html,body').animate({
          scrollTop: $(".buying").offset().top},
          'fast');
    });
  
   })



  let heroMobileDetailsPack

 function herocallmobile(){ 
   if(hero.length > 0){ 

      heroMobileDetailsPack = hero.map((hero, i) => 
      <Carousel.Item className="landingCarousel" >
        <span className="elementor-background-overlay"></span>
        <img src={hero[0].mobileimage} width="100%" className="landingCarousel zoom" />
        <Carousel.Caption className="landingCarouselCaption">
          <h1 className="bannerText2"> {hero[0].message} </h1>
          <p> {hero[0].submessage} </p>
          <button className="cta-primary hero-banner__content--btn buy" >
            
             <span>Buy A Policy</span>
    
        </button>
  
        </Carousel.Caption>
      </Carousel.Item>
  
      
    
    )
  

  
   }
  
 } 

$.when(herocallmobile()).then(function(){ 
  $(".buy").click(function() {

    $('html,body').animate({
        scrollTop: $(".buying").offset().top},
        'fast');
  });

 })


  let spotlightDetailsPack
  if(productSpotlight.length > 0){ 

  spotlightDetailsPack = productSpotlight.map((product, i) => 
   <section className="section pd-100-60 basic-panel promotion" data-pru-link="">
  <div className="row clearSpaceBottom1">
  
  <div className="col-12 col-md-7"> 
                  <div className="basic-panel__wrapper">
                      <div className="container">
                              <div className="basic-panel__outer">
                                  <div className="basic-panel__outer--img">
                                      <picture>
                                          <source media="(max-width: 992px)" data-srcset=""/>
                                              <img className=" ls-is-cached lazyloaded" src={product[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                      </picture>
                                  </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div className="col-12 col-md-5"> 
                              <div className="basic-panel__content-outer textGroupPosition">
                                  <div className="container">
                                      <div className="row">
                                          <div className="basic-panel__content">
                                              <div className="basic-panel__content--wrapper">
                                                  <h2 className="basic-panel__content--title">
                                                      <a name="easyswitch" id="easyswitch"></a>
                                                      <h1 className="bannerText"> {product[0].boldText}  <br/> <span className="lightFont"> {product[0].lightText} </span> </h1>
                                                      
                                                  </h2>
                                                  <p> {product[0].subheading} </p>
  
                                           
  
                                                          <a className="btn-link gtm_PromotionBanner link" href={product[0].link} title="Learn more"> {product[0].linkname} <img src={arrow} /> </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                  
             
                  </div>
              </section>
  
  )

}



let introDetailsPack

if(intro.length > 0){ 
  
  introDetailsPack = intro.map((introduction, i) => 
    <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
  <div className="container">
      

      <div className="component-rich-text__wrapper">
<div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h2>
  <span> {introduction[0].boldText} </span>
   <span className="lightFont">{introduction[0].lightText}</span>
   </h2>
   <br/>

<p className="editorial-body-copy"> {introduction[0].descriptiona} </p>
<p className="editorial-body-copy"> {introduction[0].descriptionb} </p>
</div>
</div>
</div>
</div>


  </div>
</section>
  
  )

}


let sectionsDetailsPack

if(sections.length > 0){ 
  
  sectionsDetailsPack = sections.map((section, i) => 
    <section className="section pd-100-60 basic-panel promotion" data-pru-link="">
  <div className="row clearSpaceBottom1">
  
  <div className="col-12 col-md-7"> 
                  <div className="basic-panel__wrapper">
                      <div className="container">
                              <div className="basic-panel__outer">
                                  <div className="basic-panel__outer--img">
                                      <picture>
                                          <source media="(max-width: 992px)" data-srcset=""/>
                                              <img className=" ls-is-cached lazyloaded" src={section[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                      </picture>
                                  </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div className="col-12 col-md-5 paddingOff"> 
                              <div className="basic-panel__content-outer textGroupPosition">
                                  <div className="container">
                                      <div className="row">
                                          <div className="basic-panel__content">
                                              <div className="basic-panel__content--wrapper">
                                                  <h2 className="basic-panel__content--title">
                                                      <a name="easyswitch" id="easyswitch"></a>
                                                      <h1 className="bannerText basic-panel__content--title"> {section[0].boldText} </h1>
                                                      
                                                  </h2>
                                                  <p> {section[0].subheading} </p>
  
                                           
  
                                                          <a className="btn-link gtm_PromotionBanner link" href={section[0].link} title="Learn more">{section[0].linkname} <img src={arrow} />  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                  
             
                  </div>
              </section>
  
  )

}


let productDetails

  if(products.length > 0){ 
  
    productDetails = products.map((product, i) => 
      <div className='item'>
<div className="card card-static inlineMargin carouselCard paddingOff">
  <a href={`/policy/individual/${product[0].id}`} className="link"> 
  <img src={product[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {product[0].heading} </h2>
    <p className="card-text"> {product[0].description} </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>
      </div>
    
    )

  }



  let lifeBlogDetails

  if(lifeBlogs.length > 0){ 
  
    lifeBlogDetails = lifeBlogs.map((blog, i) => 
      <div className='item'>
<div className="card card-static inlineMargin carouselCard paddingOff">
  <a href={`/blog/life-insurance/${blog[0].id}`} className="link"> 
  <img src={blog[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {blog[0].heading} </h2>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>
      </div>
    
    )

  }



  let healthBlogDetails

  if(healthBlogs.length > 0){ 
  
 healthBlogDetails = healthBlogs.map((blog, i) => 
      <div className='item'>
<div className="card card-static inlineMargin carouselCard paddingOff">
  <a href={`/blog/health-and-wellness/${blog[0].id}`} className="link"> 
  <img src={blog[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {blog[0].heading} </h2>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>
      </div>
    

  )
  }



  if(marketBlogs.length > 0){ 
  
    marketBlogDetails = marketBlogs.map((blog, i) => 
      <div className='item'>
<div className="card card-static inlineMargin carouselCard paddingOff">
  <a href={`/blog/markets/${blog[0].id}`} className="link"> 
  <img src={blog[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {blog[0].heading} </h2>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>
      </div>
    
    )

  }


 



  if(products.length > 0){

    
  return(
  
    
  <div className="container-fluid paddingOff">
        <header> 
   <Navigation />
      </header>
<div className="clearSpaceBottom d-none d-sm-block"> 

             <Carousel className="landingCarousel" controls={false} indicators={false}>
            
    {heroDetailsPack}
    </Carousel>
    
    </div>
    <div className="clearSpaceBottom d-block d-sm-none"> 
             <Carousel className="landingCarousel" controls={false} indicators={false}>
    {heroMobileDetailsPack}
    </Carousel>
    
    </div>

<div className="container widthOverride"> 

{spotlightDetailsPack}

</div>



{introDetailsPack}

<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>

<div className="col-12 col-md-6 buying"> 
<h1 className="basic-panel__content--title"> Future Ready.  <span className="lightFont"> It starts here</span> </h1>
<p> At Prudential Insurance, our reason is you ! </p>
</div>

<div className="col-12"> 
<OwlCarousel className='owl-theme product-owl' loop margin={30} items={3} dots={true} nav>
     
    {productDetails}
 

</OwlCarousel>

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>

<div className="col-12 col-md-6"> 
<h1 className="basic-panel__content--title"> Self Service.  <span className="lightFont"> Here to serve </span> </h1>
<p> Complete activities around managing your account with us online or speak to an advisor </p>
</div>

<div className="row inlineDisplay"> 



<div className="card card-static inlineMargin carouselCard paddingOff">
<a href="https://jcms.prudentialzenith.com/paystackui/" className="link" target="_blank">
  <img src={renew} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Renew my plan  </h2>
    <p className="card-text"> Continue to live life with certainity </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>

   

 
      
      <div className="card card-static inlineMargin carouselCard paddingOff">
      <a href="/claims" className="link"> 
  <img src={makeClaims} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Make a claim </h2>
    <p className="card-text"> Let's support you now it matters most </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>

   



<div className="card card-static inlineMargin carouselCard paddingOff">
<a href="/contact" className="link"> 
  <img src={advisorContact} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Talk to an advisor </h2>
    <p className="card-text"> Get premium guidiance in planning  </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </a>
      </div>

 

</div>

</div>
</div>
</section>

<div className="container widthOverride"> 

{sectionsDetailsPack}

</div>



<div className="container widthOverride"> 


            <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>
<div className="row paddingBottom"> 
<div className="col-12 col-md-4"> 
<h1 className="basic-panel__content--title"> What's Hot  </h1>

</div>



<div className="col-12 offset-md-1 col-md-6 text-end"> 
<ul class="nav nav-tabs offset-md-3 col-12" id="myTabs" role="tablist">
  <li class="nav-item filter-tab-item" role="presentation">
    <button class="nav-link tabs-btn active" id="lifeblog"  data-bs-toggle="tab" data-bs-target="#live-tab" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"> Life </button>
  </li>
  <li class="nav-item filter-tab-item" role="presentation">
    <button class="nav-link tabs-btn" id="healthblog" data-bs-toggle="tab"  data-bs-target="#health-tab" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"> Health </button>
  </li>
  <li class="nav-item filter-tab-item" role="presentation">
    <button class="nav-link tabs-btn" id="Markets" data-bs-toggle="tab" data-bs-target="#market-tab" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"> Markets </button>
  </li>

</ul>

</div>
</div>

<div class="tab-content" id="myTabContent">



  <div class="tab-pane fade" id="health-tab" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
    <OwlCarousel2 className='owl-theme blog-owl' loop={false} margin={30} items={1} dots={true} nav>
     
    {healthBlogDetails}
 

</OwlCarousel2>

    </div>

    <div class="tab-pane fade active show" id="live-tab" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
    <OwlCarousel2 className='owl-theme blog-owl' loop={false} margin={30} items={3} dots={true} nav>
     
    {lifeBlogDetails}
 

</OwlCarousel2>

    </div>
  <div class="tab-pane fade" id="market-tab" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
  
<OwlCarousel3 className='owl-theme blog-owl' loop={false} margin={30} items={3} dots={true} nav>
     
    {marketBlogDetails}
 

</OwlCarousel3>
    </div>


</div>

</div>
</div>
</section>

</div>

<footer>
         <Footer />
        </footer>
    </div>



  )

   }

 }

 export default Individual