import './App.css';
import $ from 'jquery';
import Individual from './components/individual';
import Corporate from './components/corporate';
import PolicyLanding from './components/policy-landing';
import Contact from './components/contact'

import Achieve from './components/achievements';
import ClaimFAQ from './components/claims-faq';
import ClaimForm from './components/claims-forms';
import ClaimGlossary from './components/claim-glossary';
import About from './components/about.js';
import Leadership from './components/leadership.js';
import BlogLanding from './components/blog-landing.js';
import BlogSingle from './components/blog-single.js';
import PolicySingle from './components/policy-single.js';
import Statements from './components/statements.js'; 
import Press from './components/press.js';
import Claims from './components/claims.js';
import ClaimPromise from './components/claims-promise.js';
import NewsRoom from './components/newsroom.js';
import Footer from './components/footer.js';
import Careers from './components/careers.js';           
//import About from './components/about.js';
import {Routes, Route} from 'react-router-dom';


function App() {
  return (
    <div>
  
    <Routes> 

       
       <Route exact path="/" element={<Individual/>}/>
       <Route exact path="/:corporate/home" element={<Corporate/>}/>
       <Route exact path="/policy/:section/home" element={<PolicyLanding/>} /> 
       <Route exact path="/contact" element={<Contact/>} /> 
       <Route exact path="/about/achievements" element={<Achieve/>} /> 
       <Route exact path="/claims/faqs" element={<ClaimFAQ/>} /> 
       <Route exact path="/claims/forms" element={<ClaimForm/>} /> 
       <Route exact path="/claims/glossary" element={<ClaimGlossary/>} /> 
       <Route exact path="/about" element={<About />} /> 
       <Route exact path="/about/leadership" element={<Leadership />} /> 
       <Route path="/blog/:section" element={<BlogLanding />} /> 
       <Route path="/blog/:section/:id" element={<BlogSingle />} /> 
       <Route path="/policy/:section/:id" element={<PolicySingle />} /> 
       <Route exact path="/about/statements" element={<Statements />} /> 
       <Route exact path="/news/press-release" element={<Press />} /> 
       <Route exact path="/claims" element={<Claims/>} /> 
       <Route exact path="/claims/promise" element={<ClaimPromise/>} /> 
       <Route exact path="/news" element={<NewsRoom/>} /> 
       <Route exact path="/about/careers" element={<Careers/>} /> 




        </Routes>

      
    </div>

  );
}

export default App;

