import "bootstrap/dist/css/bootstrap.css";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import contact from '../images/contact.jpg';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg'
import Footer from './footer.js';
import Navigation from './nav';



 function Contact() {
  window.scrollTo(0, 0)


  let { register, handleSubmit, watch, formState: { errors } } = useForm()
  let onSubmit = async function (data){
    
      
        
    
        
      let submitTestDriveRequest = await fetch('https://charisbrain.herokuapp.com/charis/fordwebsiteservices/mail/test-drive',{ 
          method: "POST",
          body:JSON.stringify(data),
          headers:{
            'Content-Type':'application/JSON',
            'Accept': 'application/JSON'
          },
          mode:'cors' 
        })
      
        .then(response => response.json())
        .then(response => { 
            alert("Contact request Submitted! Expect a call soon")
    
        })
       
  
  
  };


  return(
       
<div className="">
<header> 
   <Navigation />
      </header>
<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
                                              <form id="form" onSubmit={handleSubmit(onSubmit)}>
                            <div class="title-form__wrapper">
                                <h1 class="title-form" data-product-title=""><span class="title-form__bold">Get In Touch With Us</span> </h1>
                                </div>
                             <div class="notification-form d-none" data-notification=""><span class="icon icon-warning-triangle notification-form__icon"></span>
                                <div class="notification-form__content">
                                  <div class="notification-form__title"> *Please fill in all fields</div>
                                  <div class="notification-form__desc" data-notification-message=""> *Please fill in all fields</div>
                                </div>
                             </div>

                             <p class="mandatory-text">All fields marked with * are mandatory</p>
        
                             <h2 class="group-field__title" data-required="">Are you a Prudential customer?</h2>
                             <fieldset class="section-radio-checkbox" data-radio-checkbox-popup="">
                                <legend class="sr-only">Are you a Prudential customer?</legend>
                                {errors.customer?.type === 'required' && <p role="alert" className="redText">Please select one</p>}
                                <div class="radio-checkbox__wrapper field-item">
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="yes">
                                                <input class="radio-checkbox__input" type="radio" name="policyRadios" id="yes" {...register("customer", {required:true})}/><span></span>Yes</label>
                                        </div>
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="no">
                                            <input class="radio-checkbox__input" type="radio" name="policyRadios" id="no" value="No" {...register("customer", {required:true})}/>
                                                <span></span>
                                                No
                                                </label>
                                        </div>     
                                    </div>
                             
                                </fieldset>


                                <div class="section-input-form">
                                  <h2 class="group-field__title">Your Enquiry</h2>
                                 
                                    
                                    <div class="input-text-field">
                                    {errors.message?.type === 'required' && <p role="alert" className="redText" >Message is required</p>}
                                          <div class="field-item">
                                               <input class="field-input" placeholder="Please let us know your questions or feedback(max 500characters) " id="enquiry" type="text" {...register("message", {required:true})} />
                                               <label class="field-placeholder" for="last-name"> </label>
                                          </div>
                                        </div>
                                    </div>

                                <div class="section-input-form">
                                  <h2 class="group-field__title">Your details</h2>
                                 
                                    <div class="input-text-field">
                                    {errors.firstname?.type === 'required' && <p role="alert" className="redText" > Firstname is required</p>}
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="text" id="first-name" {...register("firstname", {required:true})}  />
                                              <label class="field-placeholder" for="first-name">First Name</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                    {errors.lastname?.type === 'required' && <p role="alert" className="redText" > Lastname is required </p>}
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="text" id="last-name" {...register("lastname", {required:true})}  />
                                               <label class="field-placeholder" for="last-name">Surname</label>
                                          </div>
                                        </div>
                                    </div>


                                    <div class="section-input-form">
                                  <h2 class="group-field__title">Preferred Mode of Contact</h2>
                                 
                                    <div class="input-text-field">
                                    {errors.mobile?.type === 'required' && <p role="alert" className="redText" >Phone number is required</p>}
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="number" {...register("mobile", {required:true})} />
                                              <label class="field-placeholder" for="first-name">Phone number</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                    {errors.email?.type === 'required' && <p role="alert" className="redText">Email is required</p>}
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="email" {...register("email", {required:true})} />
                                               <label class="field-placeholder" for="last-name">Email</label>
                                          </div>
                                        </div>
                                    </div>

                                    <button className="cta-primary hero-banner__content--btn">
            
                                            <span> Submit</span>      
       
                                         </button>
                              

                             </form>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={contact} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
            <div className="container">
                

                <div className="component-rich-text__wrapper">
< div className="col-12 col-md-6 offset-md-6"> 
<h2 className="bannerText"> <strong> Prefer to speak </strong> <span className="lightFont"> to us </span> </h2>
</div>
<div className="bk-blue-gray2"></div>
<div className="container-rich-text">
<div className="row">
<div className="col-12 col-md-6 standardPadding">
 
<p className="editorial-body-copy">
    Head office General Line
    </p>
  <h2 className="editorial-body-copy">
    <strong> 02018887600 </strong>
  </h2>
  <p className="editorial-body-copy">
    Monday - Friday: 8:00am to 5:00pm <br/>
    (Excluding public holidays)
    </p>
  </div>

  <div className="col-12 col-md-6 standardPadding">
 
  <p className="editorial-body-copy">
   Call Center
    </p>
  <h2 className="editorial-body-copy">
    <strong> 02018887600 </strong>
  </h2>
  <p className="editorial-body-copy">
    Monday - Friday: 8:00am to 5:00pm <br/> 
    (Excluding public holidays)
    </p>
  </div>
</div>
</div>
</div>


            </div>
        </section>



        <footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default Contact;